import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { getAppBaseUrl } from './actions/api'

const BASE_URL = getAppBaseUrl()

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}))

function CCBillForm(props) {
    const [_ccbillId_firstName, changeFirstName] = useState('')
    const [_ccbillId_lastName, changeLastName] = useState('')
    const [_ccbillId_address1, changeAddress1] = useState('')
    const [_ccbillId_city, changeCity] = useState('')
    const [_ccbillId_state, changeState] = useState('')
    const [_ccbillId_country, changeCountry] = useState('')
    const [_ccbillId_postalCode, changePostalCode] = useState('')
    const [_ccbillId_email, changeEmail] = useState('')
    const [_ccbillId_cardNumber, changeCardNumber] = useState('')
    const [_ccbillId_expMonth, changeExpMonth] = useState('')
    const [_ccbillId_expYear, changeExpYear] = useState('')
    const [_ccbillId_nameOnCard, changeNameOnCard] = useState('')
    const [_ccbillId_cvv, changeCvv] = useState('')
    const [isPaymentTokenGenerating, changeIsPaymentTokenGenerating] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault()
        changeIsPaymentTokenGenerating(true)
        let data = {
            _ccbillId_firstName,
            _ccbillId_lastName,
            _ccbillId_address1,
            _ccbillId_city,
            _ccbillId_state,
            _ccbillId_country,
            _ccbillId_postalCode,
            _ccbillId_email,
            _ccbillId_cardNumber,
            _ccbillId_expMonth,
            _ccbillId_expYear,
            _ccbillId_nameOnCard,
            _ccbillId_cvv
        }
        axios.post(`${BASE_URL}/payment_token`, data).then(res => {
            if (res.data.error) {
                changeIsPaymentTokenGenerating(false)
                alert(`Error: ${res.data.error}`)
                return alert(`Failure code: ${res.data.failureCode}`)
            }

            if (res.data.accepted === true) {
                changeIsPaymentTokenGenerating(false)
                return alert(res.data.message)
            }
        }).catch((error) => {
            console.log(error)
            alert(error.response.data.generalMessage)
            alert(`Error code: ${error.response.data.errorCode}`)
            alert(`Error ID: ${error.response.data.id}`)
            changeIsPaymentTokenGenerating(false)
        })
    }

    return (
        <div className='container mt-2'>
            <div className='row text-center'>
                <div className='col'>
                    <form autoComplete='off' onSubmit={onSubmit}>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_firstName'
                                label='First Name'
                                variant='outlined'
                                value={_ccbillId_firstName}
                                name='_ccbillId_firstName'
                                onChange={(e) => changeFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_lastName'
                                label='Last Name'
                                variant='outlined'
                                value={_ccbillId_lastName}
                                name='_ccbillId_lastName'
                                onChange={(e) => changeLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_address1'
                                label='Address 1'
                                variant='outlined'
                                value={_ccbillId_address1}
                                name='_ccbillId_address1'
                                onChange={(e) => changeAddress1(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_city'
                                label='City'
                                variant='outlined'
                                value={_ccbillId_city}
                                name='_ccbillId_city'
                                onChange={(e) => changeCity(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_state'
                                label='State'
                                variant='outlined'
                                value={_ccbillId_state}
                                name='_ccbillId_state'
                                onChange={(e) => changeState(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_country'
                                label='Country'
                                variant='outlined'
                                value={_ccbillId_country}
                                name='_ccbillId_country'
                                onChange={(e) => changeCountry(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_postalCode'
                                label='Postal Code'
                                variant='outlined'
                                value={_ccbillId_postalCode}
                                name='_ccbillId_postalCode'
                                onChange={(e) => changePostalCode(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_email'
                                label='Email'
                                variant='outlined'
                                value={_ccbillId_email}
                                name='_ccbillId_email'
                                onChange={(e) => changeEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_cardNumber'
                                label='Credit Card Number'
                                variant='outlined'
                                value={_ccbillId_cardNumber}
                                name='_ccbillId_cardNumber'
                                onChange={(e) => changeCardNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_expMonth'
                                label='Expiration Month'
                                variant='outlined'
                                value={_ccbillId_expMonth}
                                name='_ccbillId_expMonth'
                                onChange={(e) => changeExpMonth(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_expYear'
                                label='Expiration Year'
                                variant='outlined'
                                value={_ccbillId_expYear}
                                name='_ccbillId_expYear'
                                onChange={(e) => changeExpYear(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_nameOnCard'
                                label='Name on Card'
                                variant='outlined'
                                value={_ccbillId_nameOnCard}
                                name='_ccbillId_nameOnCard'
                                onChange={(e) => changeNameOnCard(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <TextField
                                id='_ccbillId_cvv'
                                label='CVV'
                                variant='outlined'
                                value={_ccbillId_cvv}
                                name='_ccbillId_cvv'
                                onChange={(e) => changeCvv(e.target.value)}
                                required
                            />
                        </div>
                        <Button variant='outlined' color='primary' type='submit' disabled={isPaymentTokenGenerating}>
                            Subscribe
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect()(withRouter(withStyles(useStyles)(CCBillForm)))
