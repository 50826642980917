import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// window.onload = function () {
//   // console.log(window.ccbill)
//   const widget = new window.ccbill.CCBillAdvancedWidget('951908')
//   try {
//     var result = widget.createPaymentToken('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsibWNuLXRyYW5zYWN0aW9uLXNlcnZpY2UiXSwic2NvcGUiOlsicmVhZF90b2tlbiIsImNoYXJnZV90b2tlbiJdLCJleHAiOjE2MTIxOTgzODEsImF1dGhvcml0aWVzIjpbIk1DTl9BUElfVE9LRU5fQ0hBR0VSIiwiTUNOX0FQSV9UT0tFTl9DUkVBVE9SIl0sImp0aSI6IjgzM2YxYTU5LWM1ODMtNDcxYi04ODdmLThkYmZkZDk4NmM3ZSIsImNsaWVudF9pZCI6IjMxNWZkYjdhNTk5YzExZWFiYjAxMDA1MDU2OWQyM2JiIn0.FeHwXYAPZG74nLXWWfPVmjsv6kAlmUThhPmHxWGJgSnMN8QV_MF4lpAn_U5ngrKb8xN0I8yWGK4sOWDZhbRxviWjw870ZXZXvV1d1MlsZCjagzSzL2lbkAlCh9vAbePKNpazZV0owwTvioy2tSii61wfiPA1LawRpLe6BW6O_znWml3-fkKpyElAqwwYAiy_7NGsLu63ugypmfDT6FtebMDcdLHtHjaJUSltpn_41rQMvNgKF-hhq4fdLzgz3jsuD6yVlmHq9wO6jNoDoAcwgCbcpWgbe5mwUGQbt7jczJvXZ6EO6XfTJIYAEmsBwS1ixGgzVVvVruZVdm4toYGNsA', '951908', '0022');
//     result.then((data) => {
//       console.log("RESULT DATA[" + data.paymentTokenId + "]");
//     },
//       (error1) => {
//         console.log('error1', error1)
//       }).catch((error2) => {
//         console.log('error2', error2)
//       });
//   } catch (error3) {
//     console.log('error3', error3)
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
